import { Link } from 'react-router-dom';
import { ReactComponent as ArrowIcon } from 'assets/images/arrow.svg';
import './styles.css';

const SemanaDevsuperior = () => {
  return (
    <div className="semanadevsuperior-details-container">
      <div className="base-card semanadevsuperior-details-card">
        <Link to="/certificados">
          <div className="semanadevsuperior-goback-container">
            <ArrowIcon />
            <h2>VOLTAR</h2>
          </div>
        </Link>

        <div>
          <div>
            <div className="semanadevsuperior-img-container">
              <img
                src="https://ik.imagekit.io/dpiu5qcwt/Certificados/Semana%20DevSuperior-sds2.png?updatedAt=1703553672030"
                alt="Semana DevSuperior, edção 2.0"
              />
            </div>
            <div className="semanadevsuperior-name-subtitle-container">
              <h1>Semana DevSuperior, edção 2.0</h1>
              <p>
                <strong>Período:</strong>&nbsp;04/01/2021 a 10/01/2021 <br />
                <strong>Código de Autenticidade:</strong>&nbsp;3403568SDS2{' '}
                <br />
                <strong>Verificar:</strong>&nbsp;
                <a
                  href="https://learn.devsuperior.com/certificados/3403568SDS2"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span>
                    <mark>Clique Aqui</mark>
                  </span>
                </a>
                <br />
                <strong>Modalidade:</strong>&nbsp;EAD <br />
                <strong>Entidade Educacional:</strong>&nbsp;Educandoweb Cursos
                LTDA <br />
                <strong>Coordenador:</strong>&nbsp;Nélio Muniz Mendes Alves{' '}
                <br />
                <strong>Considerações:</strong>&nbsp;Durante uma semana a{' '}
                <strong>DevSuperior</strong>{' '}
                realizou um evento totalmente Hands On, com propósito de criar
                um aplicativo do zero à implantação, onde foi nos ensinados a
                trabalhar com as principais tecnologia do mercado. <br />
                Para construirmos o Backend da aplicação utilizamos o{' '}
                <mark>Java 11</mark> e o framework <mark>Spring Boot</mark>,
                adotamos o padrão <mark>MVC</mark>, dividindo as
                responsabilidades de cada componete. <br />
                Na construção do Frontend, utilizamos o TypeScript com o
                ReactJs, criamos um aplicativo de Deliver de Pizza, onde o
                usuário pode escolher um ou mais itens, adicionar um endereço de
                entrega e fazer um pedido. <br />
                <strong>Projeto em Produção:</strong>&nbsp;
                <a
                  href="https://tiagogomesds2.netlify.app/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span>tiagogomesds2.netlify.app</span>
                </a>{' '}
                <br />
                <strong>Código Fonte:</strong>&nbsp;
                <a
                  href="https://github.com/tiagogomes187/dsdeliver-sds2"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span>github.com/tiagogomes187/dsdeliver-sds2</span>
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SemanaDevsuperior;
