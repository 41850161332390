import CertificateCard from 'components/CertificateCard';
import { Link } from 'react-router-dom';
import { Certificado } from 'types/certificado';
import { Outlet } from 'react-router-dom';
import './styles.css';

const Certificate = () => {

  const formacaoJava: Certificado = {
    id: 1,
    title: 'Formação Java Developer',
    subtitle: 'Certificado de 76 horas na Dio.me',
    description:
      'Durante a formação participei de 35 cursos, 7 desafios de projeto e 2 desafios de códigos, aprendi os principais fundamentos da plataforma Java e os principais conceitos e boas práticas de programação.',
    imgUrl:
      'https://ik.imagekit.io/dpiu5qcwt/Certificados/FormacaoJavaDeveloper.png?updatedAt=1711321688468',
  };

  const certificado: Certificado = {
    id: 2,
    title: 'Bootcamp Spring 3.0',
    subtitle: 'Certificado de 160 horas na DevSuperior',
    description:
      'Durante o treinamento foram entregues e validados projetos nas tecnologias Java e Spring Boot, abordando tópicos tais como CRUD, TDD, OAuth2, ORM, consultas, casos de uso, dentre outros.',
    imgUrl:
      'https://ik.imagekit.io/dpiu5qcwt/Certificados/Bootcamp%20Spring%20edi%C3%A7%C3%A3o.png?updatedAt=1703282995501',
  };

  const poo: Certificado = {
    id: 3,
    title: 'Java Completo POO',
    subtitle: 'Certificado de 50 horas na Udemy.com',
    description: 'Certificado de 50 horas na Udemy.com.',
    imgUrl:
      'https://ik.imagekit.io/dpiu5qcwt/Certificados/POO-Jaava.jpg?updatedAt=1703296098132',
  };

  const sistemaavancado: Certificado = {
    id: 4,
    title: 'Arquitetura de Sistemas Avançado',
    subtitle: '3 horas Ead na DIO',
    description: '3 horas Ead na DIO.',
    imgUrl:
      'https://ik.imagekit.io/dpiu5qcwt/Certificados/Arquitetura-Sistemas-Avancado.PNG?updatedAt=1703300663530',
  };

  const collections: Certificado = {
    id: 5,
    title: 'Implementando Collections e Streams com Java',
    subtitle: '6 horas Ead na DIO',
    description: '6 horas Ead na DIO.',
    imgUrl:
      'https://ik.imagekit.io/dpiu5qcwt/Certificados/collections_stremas_java.png?updatedAt=1703458674212',
  };

  const desenvolvimento_aavncado_java: Certificado = {
    id: 6,
    title: 'Desenvolvimento avançado em Java',
    subtitle: '8 horas Ead na DIO',
    description: '8 horas Ead na DIO.',
    imgUrl:
      'https://ik.imagekit.io/dpiu5qcwt/Certificados/desenvolvimento_aavncado_java.png?updatedAt=1703460004957',
  };

  const ecommercejava: Certificado = {
    id: 7,
    title: 'Criando uma solução de e-commerce com microsserviços em Java',
    subtitle: '6 horas Ead na DIO',
    description: '6 horas Ead na DIO.',
    imgUrl:
      'https://ik.imagekit.io/dpiu5qcwt/Certificados/Criando_e-commerce_microsservicos_java.png?updatedAt=1703460704211',
  };

  const restapirestful: Certificado = {
    id: 8,
    title: 'Rest Apis Restful do 0 à AWS com Spring Boot 2.x e Docker',
    subtitle: '31,5 horas Ead na Udemy.',
    description: '31,5 horas Ead na Udemy.',
    imgUrl:
      'https://ik.imagekit.io/dpiu5qcwt/Certificados/Rest_Apis_restful_aws.png?updatedAt=1703541874978',
  };

  const bootcampreact: Certificado = {
    id: 9,
    title: 'Bootcamp React 3.0',
    subtitle: 'Certificado de 160 horas na DevSuperior',
    description:
      'Durante o treinamento foram entregues e validados projetos ReactJS, abordando tópicos tais como layout responsivo, hooks, rotas, segurança, CRUDs, integrações, dashboards, dentre outros.',
    imgUrl:
      'https://ik.imagekit.io/dpiu5qcwt/Certificados/Bootcamp%20Spring%20edi%C3%A7%C3%A3o.png?updatedAt=1703282995501',
  };

  const semanadevsuperior: Certificado = {
    id: 10,
    title: 'Semana DevSuperior, edição 2.0',
    subtitle: 'Certificado de 16 horas na DevSuperior',
    description:
      'Pela conclusão com êxito do desenvolvimento e publicação, sob nossa orientação, de um aplicativo web usando Java com Spring Boot no back end, TypeScript com ReactJS no front end. Esta realização foi obtida no evento Semana DevSuperior, edição 2.0, ocorrido de 04/01/2021 a 10/01/2021.',
    imgUrl:
      'https://ik.imagekit.io/dpiu5qcwt/Certificados/Semana%20DevSuperior-sds2.png?updatedAt=1703553672030',
  };

  const arquivosLinux: Certificado = {
    id: 11,
    title: 'Manipulando Arquivos no Linux',
    subtitle: 'Certificado de 3 horas na DIO',
    description:
      'Pela conclusão com êxito no gerenciamento de arquivos no Linux.',
    imgUrl:
      'https://ik.imagekit.io/dpiu5qcwt/Certificados/597E48E4.png?updatedAt=1704325338378',
  };

  const apiCleanArchitecture: Certificado = {
    id: 12,
    title: 'Introdução aos Conceitos de API e Clean Architecture',
    subtitle: 'Certificado de 4 horas na DIO',
    description:
      'Introdução aos Conceitos de API e Clean Architecture.',
    imgUrl:
      'https://ik.imagekit.io/dpiu5qcwt/Certificados/600718B0.png?updatedAt=1704326831579',
  };

  const conceitosPostgreSql: Certificado = {
    id: 13,
    title: 'Conceitos e melhores práticas com bancos de dados PostgreSQL',
    subtitle: 'Certificado de 9 horas na DIO',
    description:
      'Conceitos e melhores práticas com bancos de dados PostgreSQL.',
    imgUrl:
      'https://ik.imagekit.io/dpiu5qcwt/Certificados/652E2772.png?updatedAt=1704327796094',
  };
  return (
    <>
      <div className="container my-4 cartificate-container">
        <div className="row cartificate-title-container">
          <h1>Certificados</h1>
        </div>

        <div className="row cartificate-card-container">
        <div className="col-sm-6 col-lg-4 col-xl-3">
            <Link to="/formacao-java-developer">
              <CertificateCard certificado={formacaoJava} />
            </Link>
          </div>

          <div className="col-sm-6 col-lg-4 col-xl-3">
            <Link to="/detalhes">
              <CertificateCard certificado={certificado} />
            </Link>
          </div>

          <div className="col-sm-6 col-lg-4 col-xl-3">
            <Link to="/java-poo">
              <CertificateCard certificado={poo} />
            </Link>
          </div>

          <div className="col-sm-6 col-lg-4 col-xl-3">
            <Link to="/sistema-avancado">
              <CertificateCard certificado={sistemaavancado} />
            </Link>
          </div>

          <div className="col-sm-6 col-lg-4 col-xl-3">
            <Link to="/semana-devsuperior">
              <CertificateCard certificado={semanadevsuperior} />
            </Link>
          </div>

          <div className="col-sm-6 col-lg-4 col-xl-3">
            <Link to="/collections-streams">
              <CertificateCard certificado={collections} />
            </Link>
          </div>

          <div className="col-sm-6 col-lg-4 col-xl-3">
            <Link to="/java-avancado">
              <CertificateCard certificado={desenvolvimento_aavncado_java} />
            </Link>
          </div>

          <div className="col-sm-6 col-lg-4 col-xl-3">
            <Link to="/ecommerce-java">
              <CertificateCard certificado={ecommercejava} />
            </Link>
          </div>
          <div className="col-sm-6 col-lg-4 col-xl-3">
            <Link to="/rest-api-restful">
              <CertificateCard certificado={restapirestful} />
            </Link>
          </div>
          <div className="col-sm-6 col-lg-4 col-xl-3">
            <Link to="/bootcamp-react">
              <CertificateCard certificado={bootcampreact} />
            </Link>
          </div>

          <div className="col-sm-6 col-lg-4 col-xl-3">
            <Link to="/arquivos-linux">
              <CertificateCard certificado={arquivosLinux} />
            </Link>
          </div>
          <div className="col-sm-6 col-lg-4 col-xl-3">
            <Link to="/api-clean-architecture">
              <CertificateCard certificado={apiCleanArchitecture} />
            </Link>
          </div>

          <div className="col-sm-6 col-lg-4 col-xl-3">
            <Link to="/conceitos-postgresql">
              <CertificateCard certificado={conceitosPostgreSql} />
            </Link>
          </div>
        </div>
      </div>
      <Outlet />
    </>
  );
};

export default Certificate;
