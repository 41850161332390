import { Link } from 'react-router-dom';
import { ReactComponent as ArrowIcon } from 'assets/images/arrow.svg';
import './styles.css';

const RestApiRestfulAws = () => {
  return (
    <div className="restapirestfulaws-details-container">
      <div className="base-card restapirestfulaws-details-card">
        <Link to="/certificados">
          <div className="restapirestfulaws-goback-container">
            <ArrowIcon />
            <h2>VOLTAR</h2>
          </div>
        </Link>

        <div>
          <div>
            <div className="restapirestfulaws-img-container">
              <img
                src="https://ik.imagekit.io/dpiu5qcwt/Certificados/Rest_Apis_restful_aws.png?updatedAt=1703541874978"
                alt="Rest Api's Restful do 0 à AWS com Spring Boot 2.x e Docker"
              />
            </div>
            <div className="restapirestfulaws-name-subtitle-container">
              <h1>
                Rest Api&apos;s Restful do 0 à AWS com Spring Boot 2.x e Docker
              </h1>
              <p>
                <strong>Período:</strong>&nbsp;01/03/2021 <br />
                <strong>Código de Autenticidade:</strong>
                &nbsp;UC-8609cd19-d639-4071-b922-d3850dc53cdc <br />
                <strong>Verificar:</strong>&nbsp;
                <a
                  href="https://www.udemy.com/certificate/UC-8609cd19-d639-4071-b922-d3850dc53cdc"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span>
                    <mark>Clique Aqui</mark>
                  </span>
                </a>
                <br />
                <strong>Modalidade:</strong>&nbsp;EAD <br />
                <strong>Entidade Educacional:</strong>&nbsp;Udemy.com <br />
                <strong>Considerações:</strong>&nbsp;O curso ensina os
                fundamentos centrais de Webservices API's REST e RESTful tanto
                na teoria(10%), quanto na prática(90%). Esse conhecimento será
                aplicado na implementação de uma API RESTful com SpringBoot 3 e
                Java 18. <br />
                <strong>Tecnologias Abordadas:</strong>&nbsp;Spring Boot 3, Java
                18, Conceitos arquiteturais do REST/RESTful, Migrations com
                Flyway, Postman, Integração com o banco de dados MySQL, Content
                Negotiation, Versionamento de API&apos;s, HATEOAS, Swagger Open API,
                Autenticação com JWT e Spring Security. <br />
                Testes unitários e de integração com:&nbsp;JUnit 5, Mockito,
                REST Assured, Testcontainers. <br />
                Upload e download de arquivos, Dockerização da Aplicação,
                Implantação na Amazon AWS, Integração Contínua na Amazon AWS com
                o Github Actions, Como consumir a API com React JS, Padrões de
                Projetos e muito mais.
                <br />
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RestApiRestfulAws;
