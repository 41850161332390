import Navbar from 'components/Navbar';
import About from 'pages/About';
import ApiCleanArchitecture from 'pages/ApiCleanArchitecture';
import ArquiteturaAvancada from 'pages/ArquiteturaAvancada';
import ArquivosLinux from 'pages/ArquivosLinux';
import BootcampReact from 'pages/BootcampReact';
import Certificate from 'pages/Certificate';
import CertificateDetails from 'pages/CertificateDetails';
import CollectionsStreams from 'pages/CollectionsStreams';
import ConceitosPostgreSql from 'pages/ConceitosPostgreSql';
import Contact from 'pages/Contact';
import DesenvolvimentoAvancadoJava from 'pages/DesenvolvimentoAvancadoJava';
import DsCatalog from 'pages/DsCatalog';
import DsDeliverProject from 'pages/DsDeliverProject';
import DsVendas from 'pages/DsVendas';
import EcommerceMicroservicesJava from 'pages/EcommerceMicroservicesJava';
import FormacaoJavaDeveloper from 'pages/FormacaoJavaDeveloper';
import Home from 'pages/Home';
import JavaPooDetails from 'pages/JavaPooDetails';
import Project from 'pages/Project';
import ProjectDetails from 'pages/ProjectDetails';
import RestApiRestfulAws from 'pages/RestApiRestfulAws';
import SemanaDevsuperior from 'pages/SemanaDevsuperior';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

const Rotas = () => (
  <BrowserRouter>
    <Navbar />
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/projetos" element={<Project />} />
      <Route path="gtincatalog" element={<ProjectDetails />} />
      <Route path="dscatalog" element={<DsCatalog />} />
      <Route path="dsvendas" element={<DsVendas />} />
      <Route path="dsdeliver" element={<DsDeliverProject />} />
      <Route path="/sobre" element={<About />} />
      <Route path="/certificados" element={<Certificate />} />
      <Route path="/formacao-java-developer" element={<FormacaoJavaDeveloper />} />
      <Route path="/detalhes" element={<CertificateDetails />} />
      <Route path="/java-poo" element={<JavaPooDetails />} />
      <Route path="/sistema-avancado" element={<ArquiteturaAvancada />} />
      <Route path="/collections-streams" element={<CollectionsStreams />} />
      <Route path="/java-avancado" element={<DesenvolvimentoAvancadoJava />} />
      <Route path="/ecommerce-java" element={<EcommerceMicroservicesJava />} />
      <Route path="/rest-api-restful" element={<RestApiRestfulAws />} />
      <Route path="/bootcamp-react" element={<BootcampReact />} />
      <Route path="/semana-devsuperior" element={<SemanaDevsuperior />} />
      <Route path="/arquivos-linux" element={<ArquivosLinux />} />
      <Route
        path="/api-clean-architecture"
        element={<ApiCleanArchitecture />}
      />
      <Route path="/conceitos-postgresql" element={<ConceitosPostgreSql />} />
      <Route path="/contato" element={<Contact />} />
    </Routes>
  </BrowserRouter>
);

export default Rotas;
