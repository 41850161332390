import './styles.css';

const Contribution = () => {
  return (
    <div className="contribution-card">
      <div className="card-container">
        <h1>Contribução e Testes</h1>

        <span>Repositório:</span>
        <p>
          {' '}
          <a
            href="https://tiagogomes.dev.br/7sJ"
            target="_blank"
            rel="noopener noreferrer"
          >
            tiagogomes.dev.br/<strong>7sJ</strong>
          </a>
        </p>

        <span>Projeto Publicado:</span>
        <p>
          {' '}
          <a
            href="https://tiagogomes.dev.br/AdN"
            target="_blank"
            rel="noopener noreferrer"
          >
            tiagogomes.dev.br/<strong>AdN</strong>
          </a>
        </p>

        <span>Logins para testes:</span>
        <p>
          <strong>Username:</strong>&nbsp;alex@gmail.com <br />
          <strong>Senha:</strong>&nbsp;123456 <br />
          <strong>Authotities:</strong>&nbsp;&#91;&#34;ROLE_OPERATOR&#34;,&#34;ROLE_ADMIN&#34;,&#34;ROLE_CLIENT&#34;&#93;<br />
          --- <br />
          <strong>Username:</strong>&nbsp;maria@gmail.com <br />
          <strong>Senha:</strong>&nbsp;123456 <br />
          <strong>Authotities:</strong>&nbsp;&#91;&#34;ROLE_OPERATOR&#34;,&#34;ROLE_CLIENT&#34;&#93;<br />

        </p>

        <span>Diretório estruturado em Monorepo</span>
        <p>
          Gtincatalog <br />
          &nbsp;&nbsp;./backend <br />
          &nbsp;&nbsp;./frontend
        </p>

        <span>Rode o backend:</span>
        <p>cd backend && ./mvnw spring-boot:run</p>

        <span>Rode o frontend:</span>
        <p>cd frontend && yarn && yarn dev</p>

        <span>Collection do Postman</span>
        <p>
          {' '}
          <a
            href="https://github.com/tiagogomes187/gtincatalog/blob/2b3090e6644e9792339de5105901910810e12d7d/collection-postman/GtinCatalog.postman_collection.json"
            target="_blank"
            rel="noopener noreferrer"
          >
            <strong>Collection: </strong>Clique aqui para baixar, depois importe
            no Postman
          </a>
        </p>
        <p>
          {' '}
          <a
            href="https://github.com/tiagogomes187/gtincatalog/blob/2b3090e6644e9792339de5105901910810e12d7d/collection-postman/gtincatalog-LOCAL.postman_environment.json"
            target="_blank"
            rel="noopener noreferrer"
          >
            <strong>Environment: </strong>Clique aqui para baixar, depois
            importe no Postman
          </a>
        </p>
      </div>
    </div>
  );
};

export default Contribution;
