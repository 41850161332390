import { Link } from 'react-router-dom';
import './styles.css';
import { ReactComponent as ArrowIcon } from 'assets/images/arrow.svg';
import ContributionDsDelivery from 'components/ContributionDsDelivery';
import LicenseCardDsDeliver from 'components/LicenseCardDsDeliver';

const DsDeliverProject = () => {
  return (
    <div className="dsdeliverproject-details-container">
      <div className="base-card dsdeliverproject-details-card">
        <Link to="/projetos">
          <div className="dsdeliver-goback-container">
            <ArrowIcon />
            <h2>VOLTAR</h2>
          </div>
        </Link>

        <div className="row">
          <div className="col-xl-6">
            <div className="img-container">
              <img
                src="https://ik.imagekit.io/dpiu5qcwt/desktop/DsDeliver-desk-mobile.png?updatedAt=1703594023970"
                alt="Comida Rápida sem Sair de Casa"
              />
            </div>
            <div className="dsdelivername-subtitle-container">
              <h1>Sabor Expresso</h1>
              <p>Comida Rápida sem Sair de Casa.</p>
            </div>
          </div>
          <div className="col-xl-6">
            <div className="dsdeliverdescription-container">
              <h2>Sobre o sistema</h2>
              <p>
                O sistema de &quot;Sabor Expresso&quot;, tem como finalidade
                conectar, com simplicidade e praticidade, milhões de
                consumidores a restaurantes, varejistas e entregadores.
                <br />O sistema foi criado utilizando a linguagem Java 11 e o
                Framework Spring Boot como Backend e o TypeScript + ReactJs como
                Frontend, o mesmo permite que o usuário acesse um catalogo de
                pratos e/ou pizzas em geral, após a escolha dos itens é
                apresentado a quantidade de itens escolhidos e o valor total a
                pagar, o usuário pode pesquisar e/ou inserir o endereço de
                entrega e em seguida clicar em &quot;Fazer Pedido&quot;. Pronto,
                o pedido será salvo em nosso banco de dados com todos os
                detalhes do pedido.
              </p>
            </div>
          </div>
        </div>
        <hr />
        <div className="dsdelivertechs-title-container">
          <h1>Tecnologias e Metodologias Utilizadas</h1>
        </div>

        <div className="dsdelivertechs-container">
          <table className="dsdelivertechs-container-table">
            {/* <tr>
              <th className="bg-primary">1° Passo</th>
              <td>
                <p>CRUD</p>
              </td>
            </tr> */}
            <tr>
              <th className="bg-primary">Ferramentas</th>
              <td>
                <p>Java 11 LTS</p>
              </td>
            </tr>
            <tr>
              <th className="bg-primary">Detalhes</th>
              <td>
                <p>
                  Este projeto é uma api programada usando a versão 11 LTS do
                  Java. Foi explorado os principais pacotes, classes e
                  interfaces que a linguagem disponibiliza, como:
                  <strong> List, Collectors, Arrays, etc… </strong>para
                  implementar os métodos necessários para as camadas de recursos
                  e serviços da aplicação.
                </p>
              </td>
            </tr>
          </table>
        </div>

        <div className="dsdelivertechs-container">
          <table className="dsdelivertechs-container-table">
            {/* <tr>
              <th className="bg-primary">2º Passo</th>
              <td>
                <p>Testes automatizados</p>
              </td>
            </tr> */}
            <tr>
              <th className="bg-primary">Ferramentas</th>
              <td>
                <p>Spring Boot</p>
              </td>
            </tr>
            <tr>
              <th className="bg-primary">Detalhes</th>
              <td>
                <p>
                  Foi utilizado o framework Spring Boot para o desenvolvimento
                  da aplicação, por ser uma tecnologia que disponibiliza uma
                  variedade de ferramentas e bibliotecas de fácil configuração.
                  Foi explorado os principais recursos fornecidos pelo Spring
                  Boot, dentre eles:{' '}
                  <strong>
                    Injeção de Dependências, Configuração de Contexto (Beans de
                    Configuração)
                  </strong>
                  , tonando a aplicação modular e flexível.
                </p>
              </td>
            </tr>
          </table>
        </div>

        <div className="dsdelivertechs-container">
          <table className="dsdelivertechs-container-table">
            {/* <tr>
              <th className="bg-primary">3º Passo</th>
              <td>
                <p>Validação e segurança</p>
              </td>
            </tr> */}
            <tr>
              <th className="bg-primary">Ferramentas</th>
              <td>
                <p>Spring Data JPA</p>
              </td>
            </tr>
            <tr>
              <th className="bg-primary">Detalhes</th>
              <td>
                <p>
                  Foi implementado interfaces que estende uma variedade de
                  recursos da JpaRepository, para acessar e gerenciar dados de
                  um ou mais banco de dados. <br />
                  Dentre os recursos, foi explorado os principais, são eles:
                  Gerenciamento de contexto, Definição de classes de entidade,
                  Acesso a dados, Transações, Integração com outras partes do
                  Spring. Tornando a aplicação padronizada e de fácil
                  manutenção.
                </p>
              </td>
            </tr>
          </table>
        </div>

        <div className="dsdelivertechs-container">
          <table className="dsdelivertechs-container-table">
            {/* <tr>
              <th className="bg-primary">4º Passo</th>
              <td>
                <p>Domínio e ORM, autorizações</p>
              </td>
            </tr> */}
            <tr>
              <th className="bg-primary">Ferramentas</th>
              <td>
                <p>Spring Security</p>
              </td>
            </tr>
            <tr>
              <th className="bg-primary">Detalhes</th>
              <td>
                <p>
                  Foi criado uma classe de configuração que estende de
                  <strong>WebSecurityConfigurerAdapter</strong> para tornar os
                  recursos da aplicação seguros, permitindo que determinados
                  serviços seja acessado com base nas regras de autorização
                  definidas na classe de configuração.
                </p>
              </td>
            </tr>
          </table>
        </div>

        <div className="dsdelivertechs-container">
          <table className="dsdelivertechs-container-table">
            {/* <tr>
              <th className="bg-primary">5º Passo</th>
              <td>
                <p>Consultas ao banco de dados</p>
              </td>
            </tr> */}
            <tr>
              <th className="bg-primary">Ferramentas</th>
              <td>
                <p>Maven</p>
              </td>
            </tr>
            <tr>
              <th className="bg-primary">Detalhes</th>
              <td>
                <p>
                  Foi adotado como gerenciador de dependências a ferramenta{' '}
                  <strong>Maven</strong>, com isto, todas as bibliotecas
                  necessárias para o funcionamento da aplicação foram facilmente
                  adicionadas ao projeto através do Maven, tornando assim a
                  manutenção fácil e automatizada.
                  <br />
                  <br />
                </p>
              </td>
            </tr>
          </table>
        </div>
        <div className="dsdelivertechs-container">
          <table className="dsdelivertechs-container-table">
            {/* <tr>
              <th className="bg-primary">5º Passo</th>
              <td>
                <p>Consultas ao banco de dados</p>
              </td>
            </tr> */}
            <tr>
              <th className="bg-primary">Ferramentas</th>
              <td>
                <p>Implantação e Publicação</p>
              </td>
            </tr>
            <tr>
              <th className="bg-primary">Detalhes</th>
              <td>
                <p>
                  Backend:&nbsp;
                  <a
                    href="https://railway.app/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span>Railway</span>
                  </a>
                  <br /> Banco de dados:&nbsp;
                  <a
                    href="https://www.postgresql.org/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span>Postgresql</span>
                  </a>
                  <br /> Front end web:&nbsp;
                  <a
                    href="https://app.netlify.com/signup"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span>Netlify</span>
                  </a>
                </p>
              </td>
            </tr>
          </table>
        </div>
        <ContributionDsDelivery />
        <LicenseCardDsDeliver />
      </div>
    </div>
  );
};

export default DsDeliverProject;
