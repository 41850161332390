import { ReactComponent as WhatsApp } from 'assets/images/Whatsapp2.svg';
import { ReactComponent as LinkedIn } from 'assets/images/Linkedin2.svg';
import { ReactComponent as GitHub } from 'assets/images/Github2.svg';
import { ReactComponent as Email } from 'assets/images/email2.svg';
import { ReactComponent as Instagram } from 'assets/images/Instagram2.svg';
import { ReactComponent as ArrowIcon } from 'assets/images/arrow.svg';

import './styles.css';
import { Link } from 'react-router-dom';

const Contact = () => {
  return (
    <div className="contact-details-container">
      <div className="base-card contact-details-card">
        <Link to="/">
          <div className="goback-container-contact">
            <ArrowIcon />
            <h2>VOLTAR</h2>
          </div>
        </Link>

        <div className="row content-contact">
          <div className="col-xl-6 title-contact-details">
            <h1>CONTATO</h1>
            <h3>Entre em contato</h3>
            <p>Para que eu possa me apresentar melhor.</p>
          </div>
          <div className="col-xl-6 contact-details-socials">
            <div className="contact-details-socials-icons">
              <div className="teste">
                <a
                  href="mailto:tiago@tiagogomes.dev.br"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Email />
                  <br />
                  <span>tiago@tiagogomes.dev.br</span>
                </a>
              </div>

              <div className="teste">
                <a
                  href="https://github.com/tiagogomes187"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span>
                    <GitHub />
                    <br />
                    /tiagogomes187
                  </span>
                </a>
              </div>

              <div className="teste">
                <a
                  href="https://www.linkedin.com/in/tiagogomes187/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span>
                    <LinkedIn />
                    <br />
                    /tiagogomes187
                  </span>
                </a>
              </div>

              <div className="teste">
                <a
                  href="https://api.whatsapp.com/send/?phone=5511994032475&text=Ol%C3%A1%20Tiago,%20tudo%20bem%20com%20voc%C3%AA?"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span>
                    <WhatsApp />
                    <br />
                    &#40;11&#41;&nbsp;99403&minus;2475
                  </span>
                </a>
              </div>

              <div className="teste">
                <a
                  href="https://www.instagram.com/tiago.gomes187"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span>
                    <Instagram />
                    <br />
                    /tiago.gomes187
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
