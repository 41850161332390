import './styles.css';

const LicenseCardDscatalog = () => {
  return (
    <div className="license-card">
      <div className="card-container">
        <h1>Licença</h1>

        <p>
          Este projeto está sob licença do MIT. Veja o arquivo{' '}
          <a
            href="https://github.com/tiagogomes187/dscatalog-bootcamp-devsuperior/blob/effafbec6c5dbbe2a7b1bddf25c531462e51672c/LICENSE"
            target="_blank"
            rel="noopener noreferrer"
          >
            <strong>LICENSE</strong>{'  '}
          </a>
          para mais detalhes.
        </p>
      </div>
    </div>
  );
};

export default LicenseCardDscatalog;
