import { Link } from 'react-router-dom';
import { ReactComponent as ArrowIcon } from 'assets/images/arrow.svg';
import './styles.css';

const CollectionsStreams = () => {
  return (
    <div className="collectionsstreams-details-container">
      <div className="base-card collectionsstreams-details-card">
        <Link to="/certificados">
          <div className="collectionsstreams-goback-container">
            <ArrowIcon />
            <h2>VOLTAR</h2>
          </div>
        </Link>

        <div>
          <div>
            <div className="collectionsstreams-img-container">
              <img
                src="https://ik.imagekit.io/dpiu5qcwt/Certificados/collections_stremas_java.png?updatedAt=1703458674212"
                alt="Arquitetura de Sistemas Avançado"
              />
            </div>
            <div className="collectionsstreams-name-subtitle-container">
              <h1>Implementando Collections e Streams com Java</h1>
              <p>
                <strong>Período:</strong>&nbsp;07/03/2021 <br />
                <strong>Código de Autenticidade:</strong>&nbsp;BD65F847 <br />
                <strong>Verificar:</strong>&nbsp;
                <a
                  href="https://www.dio.me/certificate/BD65F847"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span>
                    <mark>Clique Aqui</mark>
                  </span>
                </a>
                <br />
                <strong>Modalidade:</strong>&nbsp;EAD <br />
                <strong>Entidade Educacional:</strong>&nbsp;Dio.me <br />
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CollectionsStreams;
