import { Link } from 'react-router-dom';
import { ReactComponent as ArrowIcon } from 'assets/images/arrow.svg';
import './styles.css';

const ConceitosPostgreSql = () => {
  return (
    <div className="conceitospostgresql-details-container">
      <div className="base-card conceitospostgresql-details-card">
        <Link to="/certificados">
          <div className="conceitospostgresql-goback-container">
            <ArrowIcon />
            <h2>VOLTAR</h2>
          </div>
        </Link>

        <div>
          <div>
            <div className="conceitospostgresql-img-container">
              <img
                src="https://ik.imagekit.io/dpiu5qcwt/Certificados/652E2772.png?updatedAt=1704327796094"
                alt="Conceitos e melhores práticas com bancos de dados PostgreSQL"
              />
            </div>
            <div className="conceitospostgresql-name-subtitle-container">
              <h1>
                Conceitos e melhores práticas com bancos de dados PostgreSQL
              </h1>
              <p>
                <strong>Período:</strong>&nbsp;01/04/2021 <br />
                <strong>Código de Autenticidade:</strong>&nbsp;652E2772 <br />
                <strong>Verificar:</strong>&nbsp;
                <a
                  href="https://www.dio.me/certificate/652E2772"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span>
                    <mark>Clique Aqui</mark>
                  </span>
                </a>
                <br />
                <strong>Modalidade:</strong>&nbsp;EAD <br />
                <strong>Entidade Educacional:</strong>&nbsp;Dio.me <br />
                <strong>Coordenador:</strong>&nbsp;Daniel Costa <br />
                <strong>Considerações:</strong>&nbsp;Com este curso aprendi como
                trabalhar com PostgreSQL, um gerenciador de banco de dados
                relacional.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConceitosPostgreSql;
