import { Link } from 'react-router-dom';
import { ReactComponent as ArrowIcon } from 'assets/images/arrow.svg';
import './styles.css';

const JavaPooDetails = () => {
  return (
    <div className="javapoo-details-container">
      <div className="base-card javapoo-details-card">
        <Link to="/certificados">
          <div className="javapoo-goback-container">
            <ArrowIcon />
            <h2>VOLTAR</h2>
          </div>
        </Link>

        <div>
          <div>
            <div className="javapoo-img-container">
              <img
                src="https://ik.imagekit.io/dpiu5qcwt/Certificados/POO-Jaava.jpg?updatedAt=1703296098132"
                alt="Java Completo POO"
              />
            </div>
            <div className="javapoo-name-subtitle-container">
              <h1>Java Completo POO + Projetos</h1>
              <p>
                <strong>Período:</strong>&nbsp;01/07/2020 a 24/01/2021 <br />
                <strong>Código de Autenticidade:</strong>&nbsp;UC-6f1198d0-e747-4f59-a405-d7b43d0f5037 <br />
                <strong>Verificar:</strong>&nbsp;
                <a
                  href="https://www.udemy.com/certificate/UC-6f1198d0-e747-4f59-a405-d7b43d0f5037/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span>
                    <mark>Clique Aqui</mark>
                  </span>
                </a>
                <br />
                <strong>Modalidade:</strong>&nbsp;EAD <br />
                <strong>Entidade Educacional:</strong>&nbsp;Udemy.com <br />
                <strong>Instrutor:</strong>&nbsp;Nélio Muniz Mendes Alves{' '}
                <br />
                <strong>Considerações:</strong>&nbsp;O certificado acima atesta
                que Tiago Gomes de Lima concluiu com êxito o curso&nbsp;
                <a
                  href="https://www.udemy.com/course/java-curso-completo/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span>
                    Java COMPLETO 2023 Programação Orientada a Objetos +Projetos
                  </span>
                </a>
                &nbsp;em 24/01/2021, ministrado por Nelio Alves na Udemy. O
                certificado indica que todo o curso foi concluído, conforme
                validado pelo aluno. A duração do curso representa a duração
                total dos vídeos no curso no momento da conclusão mais recente.
                <br />
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JavaPooDetails;
