import { Certificado } from 'types/certificado';
import './styles.css';

type Props = {
  certificado: Certificado;
};

const CertificateCard = ({ certificado }: Props) => {
  return (
    <>
      <div className="base-card certificate-card">
        <div className="card-top-container">
          <img src={certificado.imgUrl} alt={certificado.title} />
        </div>
        <div className="card-bottom-container">
          <h6>{certificado.title}</h6>
          <p>{certificado.subtitle}</p>
        </div>
      </div>
    </>
  );
};

export default CertificateCard;
