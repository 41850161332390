import './styles.css';

const ContributionDsVendas = () => {
  return (
    <div className="contribution-card">
      <div className="card-container">
        <h1>Contribução e Testes</h1>

        <span>Repositório:</span>
        <p>
          {' '}
          <a
            href="https://tiagogomes.dev.br/JfM"
            target="_blank"
            rel="noopener noreferrer"
          >
            tiagogomes.dev.br/JfM
          </a>
        </p>

        <span>Projeto Publicado:</span>
        <p>
          {' '}
          <a
            href="https://tiagogomes.dev.br/dsvendas"
            target="_blank"
            rel="noopener noreferrer"
          >
            tiagogomes.dev.br/dsvendas
          </a>
        </p>

        <span>Diretório estruturado em Monorepo</span>

        <p>
          Dsvendas <br />
          &nbsp;&nbsp;./backend <br />
          &nbsp;&nbsp;./frontend
        </p>

        <span>Rode o backend:</span>
        <p>cd backend && ./mvnw spring-boot:run</p>

        <span>Rode o frontend:</span>
        <p>cd frontend && yarn && yarn start</p>
      </div>
    </div>
  );
};

export default ContributionDsVendas;
