import { Projects } from 'types/project';
import './styles.css';

type Props = {
  project: Projects;
};

const ProjectCard = ({ project }: Props) => {
  return (
    <>
      <div className="base-card project-card">
        <div className="card-top-container">
          <img src={project.imgUrl} alt={project.title} />
        </div>
        <div className="card-bottom-container">
          <h6>{project.title}</h6>
          <p>{project.subtitle}</p>
        </div>
      </div>
    </>
  );
};

export default ProjectCard;
