import './styles.css';

const ContributionDsDelivery = () => {
  return (
    <div className="contributiondsdelivery-card">
      <div className="card-container">
        <h1>Contribução e Testes</h1>

        <span>Repositório:</span>
        <p>
          {' '}
          <a
            href="https://tiagogomes.dev.br/hgO"
            target="_blank"
            rel="noopener noreferrer"
          >
            tiagogomes.dev.br/hgO
          </a>
        </p>

        <span>Projeto Publicado:</span>
        <p>
          {' '}
          <a
            href="https://tiagogomes.dev.br/ZhW"
            target="_blank"
            rel="noopener noreferrer"
          >
            tiagogomes.dev.br/ZhW
          </a>
        </p>

        <span>Diretório estruturado em Monorepo</span>

        <p>
          dsdeliver-sds2
          <br />
          &nbsp;&nbsp;./backend <br />
          &nbsp;&nbsp;./front-mobile <br />
          &nbsp;&nbsp;./front-web
        </p>

        <span>Rode o backend:</span>
        <p>cd backend && ./mvnw spring-boot:run</p>

        <span>Rode o frontend:</span>
        <p>cd front-web && yarn && yarn start</p>
      </div>
    </div>
  );
};

export default ContributionDsDelivery;
