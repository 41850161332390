import './styles.css';

const LicenseCardDsVendas = () => {
  return (
    <div className="license-card">
      <div className="card-container">
        <h1>Licença</h1>

        <p>
          Este projeto está sob licença do MIT. Veja o arquivo{' '}
          <a
            href="https://github.com/tiagogomes187/projeto-sds3/blob/main/LICENSE"
            target="_blank"
            rel="noopener noreferrer"
          >
            <strong>LICENSE</strong>
            {'  '}
          </a>
          para mais detalhes.
        </p>
      </div>
    </div>
  );
};

export default LicenseCardDsVendas;
