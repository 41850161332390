import { ReactComponent as MainImage } from '../../assets/images/developer-red.svg';
import './styles.css';

const Home = () => {
  return (
    <div className="home-container">
      <div className="base-card home-card">
        <div className="home-content-container">
          <div>
            <h1>Olá, sou Tiago Gomes Dev Java Júnior</h1>
            <p>
              Apaixonado por tecnologia, futebol e busca do desenvolvimento
              profissional na área de TI. Seja bem vindo à minha página.
              Convido-o (a) a conhecer um pouco sobre meu trabalho e minha
              pessoa. Caso te agrade, seria um prazer ter seu contato. Agradeço
              sua visita e espero que tenha uma boa experiência na minha página.
            </p>
          </div>
          {/* <div>
            <Link to="sobre">
              <ButtonIcon text="Saiba mais sobre mim" />
            </Link>
          </div> */}
        </div>
        <div className="home-image-container">
          <MainImage />
        </div>
      </div>
    </div>
  );
};

export default Home;
