import { Link } from 'react-router-dom';
import './styles.css';
import { ReactComponent as ArrowIcon } from 'assets/images/arrow.svg';
import Contribution from 'components/Contribution';
import LicenseCard from 'components/LicenseCard';

const ProjectDetails = () => {
  return (
    <div className="project-details-container">
      <div className="base-card project-details-card">
        <Link to="/projetos">
          <div className="goback-container">
            <ArrowIcon />
            <h2>VOLTAR</h2>
          </div>
        </Link>

        <div className="row">
          <div className="col-xl-6">
            <div className="catalog-product-img-container">
              <img
                src="https://ik.imagekit.io/dpiu5qcwt/desktop/frontend-gtincatalog.png?updatedAt=1703201351028"
                alt="Catálogo de Produtos"
              />
            </div>
            <div className="name-subtitle-container">
              <h1>Catálogo de Produtos</h1>
              <p>Calçados para todos os gostos.</p>
            </div>
          </div>
          <div className="col-xl-6">
            <div className="description-container">
              <h2>Sobre o sistema</h2>
              <p>
                O sistema foi concebido e desenvolvido com o objetivo de atender
                às demandas do cliente, proporcionando uma exposição eficiente
                de seus produtos tanto para clientes quanto para colaboradores,
                de maneira intuitiva e ágil. <br />
                <br />
                O sistema deve gerenciar de forma eficiente o cadastro de
                usuários, produtos e suas respectivas categorias. Cada usuário
                deve ser registrado com informações detalhadas, incluindo nome,
                e-mail, telefone, data de nascimento e uma senha de acesso
                segura. <br />
                <br />
                No que diz respeito aos produtos, o sistema deve armazenar dados
                como nome, descrição, preço, imagem, SKU, marca, NCM
                (Nomenclatura Comum do Mercosul), código EAN (European Article
                Number), e data de fabricação. <br />
                <br />
                Para facilitar a experiência do usuário, o sistema deve oferecer
                um <strong>catálogo de produtos</strong> acessível, permitindo
                filtrar a busca pelo nome do produto. A partir desse catálogo,
                os usuários podem selecionar um produto específico para
                visualizar seus detalhes de maneira clara e intuitiva.
                <br />
                <br />
                Além disso, usuários com privilégios administrativos terão
                acesso a uma área exclusiva, a qual proporciona a administração
                dos cadastros de usuários, produtos e categorias de forma
                centralizada e eficaz. Isso possibilita uma gestão facilitada,
                contribuindo para a eficiência e organização do sistema como um
                todo.
              </p>
            </div>
          </div>
        </div>
        <hr />
        <div className="techs-title-container">
          <h1>Tecnologias e Metodologias Utilizadas</h1>
        </div>

        <div className="techs-container">
          <table className="techs-container-table">
            <tr>
              <th className="bg-primary">1° Passo</th>
              <td>
                <p>CRUD</p>
              </td>
            </tr>
            <tr>
              <th className="bg-primary">Ferramentas</th>
              <td>
                <p>
                  Java, Spring Boot, Maven, H2, Postgresql, Postman, Spring Data
                  JPA
                </p>
              </td>
            </tr>
            <tr>
              <th className="bg-primary">Detalhes</th>
              <td>
                <p>
                  Este projeto foi baseado na versão LTS do Java 17. Atualmente
                  encontra-se publicado nas plataformas,{' '}
                  <strong>Railway</strong> e <strong>Netlify</strong>. Esta
                  aplicação corporativa é focada em escalabilidade e desempenho,
                  aproveitando as últimas características do Java 17 para
                  otimizar a eficiência e segurança do código.
                  <br /> <br />A implementação de uma arquitetura modular tem
                  contribuído significativamente para facilitar a manutenção do
                  sistema. A integração de tecnologias modernas, desde a fase de
                  desenvolvimento até a fase de produção, fortaleceu a solidez
                  da solução, proporcionando uma experiência robusta aos
                  usuários.
                  <br /> <br />
                  Os resultados obtidos até o momento são promissores, e estou
                  confiante de que este projeto está plenamente alinhado com as
                  melhorias oferecidas pela versão LTS do Java. Este ambiente de
                  produção reflete não apenas a eficiência do código, mas também
                  a capacidade de adaptação e integração das mais recentes
                  inovações da linguagem Java.
                </p>
              </td>
            </tr>
          </table>
        </div>

        <div className="techs-container">
          <table className="techs-container-table">
            <tr>
              <th className="bg-primary">2º Passo</th>
              <td>
                <p>Testes automatizados</p>
              </td>
            </tr>
            <tr>
              <th className="bg-primary">Ferramentas</th>
              <td>
                <p>JUnit, Mockito, MockBean</p>
              </td>
            </tr>
            <tr>
              <th className="bg-primary">Detalhes</th>
              <td>
                <p>
                  Foram implementados testes automatizados por meio do JUnit
                  para testes de unidade, Mockito para simular comportamentos de
                  objetos e o MockBean para testes de integração, garantindo
                  cobertura abrangente e confiabilidade no desenvolvimento.
                </p>
              </td>
            </tr>
          </table>
        </div>

        <div className="techs-container">
          <table className="techs-container-table">
            <tr>
              <th className="bg-primary">3º Passo</th>
              <td>
                <p>Validação e segurança</p>
              </td>
            </tr>
            <tr>
              <th className="bg-primary">Ferramentas</th>
              <td>
                <p>Bean Validation, Spring Security, JWT, OAuth2</p>
              </td>
            </tr>
            <tr>
              <th className="bg-primary">Detalhes</th>
              <td>
                <p>
                  Foi implementadas as camadas de validação e segurança para
                  assegurar a integridade e proteção dos dados. Utilizei a
                  especificação <strong>Bean Validation</strong> para realizar
                  validações em tempo de execução, garantindo que os dados
                  atendam aos requisitos estabelecidos. <br />
                  <br />
                  Para controle de acesso, adotei o Spring Security, uma
                  ferramenta poderosa que facilita a implementação de
                  autenticação e autorização.
                  <br />
                  <br />
                  Na gestão de identidades, escolhi utilizar{' '}
                  <strong>JSON Web Tokens (JWT)</strong> em conjunto com{' '}
                  <strong>OAuth2</strong>. Essas tecnologias proporcionam um
                  método seguro e eficiente para autenticar usuários, garantindo
                  a confidencialidade e integridade das informações transmitidas
                  entre o cliente e o servidor.
                </p>
              </td>
            </tr>
          </table>
        </div>

        <div className="techs-container">
          <table className="techs-container-table">
            <tr>
              <th className="bg-primary">4º Passo</th>
              <td>
                <p>Domínio e ORM, autorizações</p>
              </td>
            </tr>
            <tr>
              <th className="bg-primary">Ferramentas</th>
              <td>
                <p>Modelo de domínio, JPA, SQL seed</p>
              </td>
            </tr>
            <tr>
              <th className="bg-primary">Detalhes</th>
              <td>
                <p>
                  Para se ter uma gestão eficiente de autorizações, utilizou-se
                  a tecnologia <strong>JPA (Java Persistence API)</strong>, um
                  modelo de domínio bem elaborado, para mapeamento
                  objeto-relacional e a semente (seed) SQL para inicialização do
                  banco de dados, projetado de forma a abranger cenários
                  complexos, proporcionando uma representação conceitual
                  completa.
                  <br />
                  <br />
                  O modelo de domínio foi projetado de forma a abranger cenários
                  complexos, proporcionando uma representação conceitual
                  completa.
                  <br />
                  <br />
                  Para garantir a segurança e autorizações customizadas, fou
                  implementado um sistema abrangente. As autorizações foram
                  personalizadas em nível de serviço, permitindo controle
                  granular sobre as operações realizadas. <br />
                  <br />A gestão de tokens também foi uma prioridade, incluindo
                  a implementação de refresh tokens para manter a autenticação
                  do usuário. Adotei pré-autorização de métodos para controlar o
                  acesso a determinadas funcionalidades, garantindo que apenas
                  usuários autorizados possam executar operações específicas.
                </p>
              </td>
            </tr>
          </table>
        </div>

        <div className="techs-container">
          <table className="techs-container-table">
            <tr>
              <th className="bg-primary">5º Passo</th>
              <td>
                <p>Consultas ao banco de dados</p>
              </td>
            </tr>
            <tr>
              <th className="bg-primary">Ferramentas</th>
              <td>
                <p>Spring Data JPA, JPQL, SQL</p>
              </td>
            </tr>
            <tr>
              <th className="bg-primary">Detalhes</th>
              <td>
                <p>
                  Foi adotada uma abordagem abrangente para consultas ao banco
                  de dados, aproveitando as funcionalidades do Spring Data JPA,
                  JPQL e SQL para otimizar o acesso e manipulação de dados.
                  <br />
                  <br />
                  Nas consultas mais simples e operações básicas de
                  persistência, foi usado o <strong>Spring Data JPA</strong>, o
                  que facilitou a criação de repositórios de dados e a execução
                  de consultas de maneira eficiente, simplificando
                  significativamente o código.
                  <br />
                  <br />
                  <strong>O desafio das consultas N+1</strong>, comuns em ORM
                  foi abordado por meio da linguagem JPQL (Java Persistence
                  Query Language), que permitiu definir consultas mais
                  específicas e orientadas a objetos, evitando o problema das
                  múltiplas consultas desnecessárias, resultando em um
                  desempenho mais eficaz.
                  <br />
                  <br />
                </p>
              </td>
            </tr>
          </table>
        </div>
        <Contribution />
        <LicenseCard />
      </div>
    </div>
  );
};

export default ProjectDetails;
