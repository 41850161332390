import { Link } from 'react-router-dom';
import { ReactComponent as ArrowIcon } from 'assets/images/arrow.svg';
import './styles.css';

const CertificateDetails = () => {
  return (
    <div className="certificate-details-container">
      <div className="base-card certificate-details-card">
        <Link to="/certificados">
          <div className="certificate-goback-container">
            <ArrowIcon />
            <h2>VOLTAR</h2>
          </div>
        </Link>

        <div>
          <div>
            <div className="certificate-img-container">
              <img
                src="https://ik.imagekit.io/dpiu5qcwt/Certificados/Bootcamp%20Spring%20edi%C3%A7%C3%A3o.png?updatedAt=1703282995501"
                alt="Bootcamp Spring 3.0"
              />
            </div>
            <div className="certificate-name-subtitle-container">
              <h1>Bootcamp Spring 3.0</h1>
              <p>
                <strong>Período:</strong>&nbsp;17/05/2021 a 20/07/2021 <br />
                <strong>Código de Autenticidade:</strong>&nbsp;1318412 <br />
                <strong>Verificar:</strong>&nbsp;
                <a
                  href="https://learn.devsuperior.com/certificados/1318412"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span>
                    <mark>Clique Aqui</mark>
                  </span>
                </a>
                <br />
                <strong>Modalidade:</strong>&nbsp;EAD <br />
                <strong>Entidade Educacional:</strong>&nbsp;Educandoweb Cursos
                LTDA <br />
                <strong>Coordenador:</strong>&nbsp;Nélio Muniz Mendes Alves{' '}
                <br />
                <strong>Considerações:</strong>&nbsp;Este Bootcamp foi muito
                importante para o meu desenvolvimento como programador, através
                das aulas ministradas pelo professor,{' '}
                <strong>Nélio Alves</strong> eu aprendir na prática a criar
                projetos reais do zero a produção, utilizando as principais
                tecnologias mais adotadas no mundo corporativo, as habilidades
                que aprendir com este curso, me preparou muito bem para atuar na
                área de TI. <br />
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CertificateDetails;
