import { Link } from 'react-router-dom';
import './styles.css';
import { ReactComponent as ArrowIcon } from 'assets/images/arrow.svg';
import { ReactComponent as JavaIcon } from 'assets/images/java.svg';
import { ReactComponent as SpringIcon } from 'assets/images/spring.svg';
import { ReactComponent as SpringBootIcon } from 'assets/images/springboot.svg';
import { ReactComponent as GitIcon } from 'assets/images/git.svg';
import { ReactComponent as PostgresIcon } from 'assets/images/postgresql.svg';
import { ReactComponent as ReactIcon } from 'assets/images/react.svg';
import { ReactComponent as HtmlIcon } from 'assets/images/html5.svg';
import { ReactComponent as CssIcon } from 'assets/images/css.svg';

const About = () => {
  return (
    <div className="about-details-container">
      <div className="base-card about-details-card">
        <Link to="/">
          <div className="goback-container">
            <ArrowIcon />
            <h2>VOLTAR</h2>
          </div>
        </Link>

        <div className="row">
          <div className="col-xl-6">
            <div className="img-container">
              <img
                src="https://ik.imagekit.io/dpiu5qcwt/Icons/PerfilGitHub.png?updatedAt=1701533807360"
                alt="Avatar"
              />
            </div>
            <div className="name-subtitle-container">
              <h2>Techs</h2>
              <div className='icons'>
                <JavaIcon />
                <SpringIcon />
                <SpringBootIcon />
                <GitIcon />
                <PostgresIcon />
                <HtmlIcon />
                <CssIcon />
                <ReactIcon />
              </div>
            </div>
          </div>

          <div className="col-xl-6">
            <div className="description-container">
              <h2>Sobre mim</h2>
              <p>
                Sou <strong>Tiago Gomes</strong>
                <br /> Dev Java Júnior
                <br />
                <br />
                Busco uma posição como Desenvolvedor Java Junior. Iniciei meus
                estudos com desenvolvimento de aplicações no ano de 2017, ao
                entrar no curso universitário "Análise e Desenvolvimento de
                Sistemas" (UNICID/São Paulo). <br />
                <br />
                Graduei em 2019 e desde então venho aprimorando meus
                conhecimentos por meio de estudos de diversas tecnologias. Uma
                das quais tenho me desenvolvido mais é a linguagem Java e os
                principais frameworks de desenvolvimento do mercado. Apesar de
                ainda não ter atuado na área de TI, tenho participado de
                diversos <strong>Bootcamps</strong> e acredito ter uma base bem
                sólida quanto a desenvolvimento de aplicações. <br />
                <br />
                Estou muito confiante e preparado para assumir uma posição como
                desenvolvedor. Dentre minhas qualidades destaco o fato de ser
                muito curioso, quanto ao funcionamento da tecnologia, motivado
                para assimilação e aprimoração de meus conhecimentos e em
                particular, busco manter-me atualizado no mundo TI. <br />
                <br />
                Atualmente estou finalizando catálogo de produtos utilizando: Java,
                Spring Boot e React.
                <br />
                <br />
                Neste momento estou buscando uma posição para atuar de forma
                efetiva numa organização que permita e incentive meu
                desenvolvimento na área. Estou à disposição!
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
