import './styles.css';

const ContributionDscatalog = () => {
  return (
    <div className="contribution-card">
      <div className="card-container">
        <h1>Contribução e Testes</h1>

        <span>Repositório:</span>
        <p>
          {' '}
          <a
            href="https://tiagogomes.dev.br/dscatalog-repo"
            target="_blank"
            rel="noopener noreferrer"
          >
            tiagogomes.dev.br/dscatalog-repo
          </a>
        </p>

        <span>Projeto Publicado:</span>
        <p>
          {' '}
          <a
            href="https://tiagogomes.dev.br/dscatalog"
            target="_blank"
            rel="noopener noreferrer"
          >
            tiagogomes.dev.br/dscatalog
          </a>
        </p>

        <span>Logins para testes:</span>
        <p>
          <strong>Username:</strong>&nbsp;maria@gmail.com <br />
          <strong>Senha:</strong>&nbsp;123456 <br />
          <strong>Authotities:</strong>
          &nbsp;&#91;&#34;ROLE_OPERATOR&#34;,&#34;ROLE_ADMIN&#34;&#93;
          <br />
          --- <br />
          <strong>Username:</strong>&nbsp;alex@gmail.com <br />
          <strong>Senha:</strong>&nbsp;123456 <br />
          <strong>Authotities:</strong>&nbsp;&#91;&#34;ROLE_OPERATOR&#34;&#93;
          <br />
        </p>

        <span>Diretório estruturado em Monorepo</span>

        <p>
          Dscatalog <br />
          &nbsp;&nbsp;./backend <br />
          &nbsp;&nbsp;./frontweb
        </p>

        <span>Rode o backend:</span>
        <p>cd backend && ./mvnw spring-boot:run</p>

        <span>Rode o frontend:</span>
        <p>cd frontweb && yarn && yarn dev</p>

        <span>Collection do Postman</span>
        <p>
          {' '}
          <a
            href="https://github.com/tiagogomes187/dscatalog-bootcamp-devsuperior/blob/78a4691b9b6fd428135a68bd0eafd14448a8cc3b/BDS%203.0%20DSCatalog%20Cap%205%20-%20LOCAL.postman_collection.json"
            target="_blank"
            rel="noopener noreferrer"
          >
            <strong>Collection: </strong>Clique aqui para baixar, depois importe
            no Postman
          </a>
        </p>
        <p>
          {' '}
          <a
            href="https://github.com/tiagogomes187/dscatalog-bootcamp-devsuperior/blob/78a4691b9b6fd428135a68bd0eafd14448a8cc3b/dscatalog-bootcamp-local.postman_environment.json"
            target="_blank"
            rel="noopener noreferrer"
          >
            <strong>Environment: </strong>Clique aqui para baixar, depois
            importe no Postman
          </a>
        </p>
      </div>
    </div>
  );
};

export default ContributionDscatalog;
