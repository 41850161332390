import { Link } from 'react-router-dom';
import './styles.css';
import { ReactComponent as ArrowIcon } from 'assets/images/arrow.svg';
import ContributionDsVendas from 'components/ContributionDsVendas';
import LicenseCardDsVendas from 'components/LicenseCardDsVendas';

const DsVendas = () => {
  return (
    <div className="ds-vendas-details-container">
      <div className="base-card ds-vendas-details-card">
        <Link to="/projetos">
          <div className="ds-vendas-goback-container">
            <ArrowIcon />
            <h2>VOLTAR</h2>
          </div>
        </Link>

        <div className="row">
          <div className="col-xl-6">
            <div className="ds-vendas-img-container">
              <img
                src="https://ik.imagekit.io/dpiu5qcwt/desktop/dsvends-dashboard.png?updatedAt=1702951674759"
                alt="Imagem do projeto"
              />
            </div>
            <div className="ds-vendas-name-subtitle-container">
              <h1>Dashboard de Vendas</h1>
              <p>
                Listagem de vendedores, quantidade de vendas e taxa de sucesso.
              </p>
            </div>
          </div>

          
          <div className="col-xl-6">
            <div className="description-container">
              <h2>Sobre o sistema</h2>
              <p>
                Dashboard de Vendas é uma aplicação full stack web construída em
                Java, Spring Boot e React. <br />
                <br />A aplicação consiste em uma listagem de vendedores, onde
                serão apresentadas informações sobre quantidade de vendas e sua
                taxa de sucesso, também apresenta um dashboard com gráficos
                baseados nestes dados.
              </p>
            </div>
          </div>
        </div>
        <hr />
        <div className="techs-title-container">
          <h1>Tecnologias e Metodologias Utilizadas</h1>
        </div>

        <div className="techs-container">
          <table className="techs-container-table">
            {/* <tr>
              <th className="bg-primary">1° Passo</th>
              <td>
                <p>CRUD</p>
              </td>
            </tr> */}
            <tr>
              <th className="bg-primary">Ferramentas</th>
              <td>
                <p>Java 11 LTS</p>
              </td>
            </tr>
            <tr>
              <th className="bg-primary">Detalhes</th>
              <td>
                <p>
                  Este projeto é uma api programada usando a versão 11 LTS do
                  Java. Foi explorado os principais pacotes, classes e
                  interfaces que a linguagem disponibiliza, como:
                  <strong> List, Collectors, Arrays, etc… </strong>para
                  implementar os métodos necessários para as camadas de recursos
                  e serviços da aplicação.
                </p>
              </td>
            </tr>
          </table>
        </div>

        <div className="techs-container">
          <table className="techs-container-table">
            {/* <tr>
              <th className="bg-primary">2º Passo</th>
              <td>
                <p>Testes automatizados</p>
              </td>
            </tr> */}
            <tr>
              <th className="bg-primary">Ferramentas</th>
              <td>
                <p>Spring Boot</p>
              </td>
            </tr>
            <tr>
              <th className="bg-primary">Detalhes</th>
              <td>
                <p>
                  Foi utilizado o framework Spring Boot para o desenvolvimento
                  da aplicação, por ser uma tecnologia que disponibiliza uma
                  variedade de ferramentas e bibliotecas de fácil configuração.
                  Foi explorado os principais recursos fornecidos pelo Spring
                  Boot, dentre eles:{' '}
                  <strong>
                    Injeção de Dependências, Configuração de Contexto (Beans de
                    Configuração)
                  </strong>
                  , tonando a aplicação modular e flexível.
                </p>
              </td>
            </tr>
          </table>
        </div>

        <div className="techs-container">
          <table className="techs-container-table">
            {/* <tr>
              <th className="bg-primary">3º Passo</th>
              <td>
                <p>Validação e segurança</p>
              </td>
            </tr> */}
            <tr>
              <th className="bg-primary">Ferramentas</th>
              <td>
                <p>Spring Data JPA</p>
              </td>
            </tr>
            <tr>
              <th className="bg-primary">Detalhes</th>
              <td>
                <p>
                  Foi implementado interfaces que estende uma variedade de
                  recursos da JpaRepository, para acessar e gerenciar dados de
                  um ou mais banco de dados. <br />
                  Dentre os recursos, foi explorado os principais, são eles:
                  Gerenciamento de contexto, Definição de classes de entidade,
                  Acesso a dados, Transações, Integração com outras partes do
                  Spring. Tornando a aplicação padronizada e de fácil
                  manutenção.
                </p>
              </td>
            </tr>
          </table>
        </div>

        <div className="techs-container">
          <table className="techs-container-table">
            {/* <tr>
              <th className="bg-primary">4º Passo</th>
              <td>
                <p>Domínio e ORM, autorizações</p>
              </td>
            </tr> */}
            <tr>
              <th className="bg-primary">Ferramentas</th>
              <td>
                <p>Spring Security</p>
              </td>
            </tr>
            <tr>
              <th className="bg-primary">Detalhes</th>
              <td>
                <p>
                  Foi criado uma classe de configuração que estende de
                  <strong>WebSecurityConfigurerAdapter</strong> para tornar os
                  recursos da aplicação seguros, permitindo que determinados
                  serviços seja acessado com base nas regras de autorização
                  definidas na classe de configuração.
                </p>
              </td>
            </tr>
          </table>
        </div>

        <div className="techs-container">
          <table className="techs-container-table">
            {/* <tr>
              <th className="bg-primary">5º Passo</th>
              <td>
                <p>Consultas ao banco de dados</p>
              </td>
            </tr> */}
            <tr>
              <th className="bg-primary">Ferramentas</th>
              <td>
                <p>Maven</p>
              </td>
            </tr>
            <tr>
              <th className="bg-primary">Detalhes</th>
              <td>
                <p>
                  Foi adotado como gerenciador de dependências a ferramenta{' '}
                  <strong>Maven</strong>, com isto, todas as bibliotecas
                  necessárias para o funcionamento da aplicação foram facilmente
                  adicionadas ao projeto através do Maven, tornando assim a
                  manutenção fácil e automatizada.
                </p>
              </td>
            </tr>
          </table>
        </div>

        <div className="techs-container">
          <table className="techs-container-table">
            {/* <tr>
              <th className="bg-primary">5º Passo</th>
              <td>
                <p>Consultas ao banco de dados</p>
              </td>
            </tr> */}
            <tr>
              <th className="bg-primary">Ferramentas</th>
              <td>
                <p>Implantação e Publicação</p>
              </td>
            </tr>
            <tr>
              <th className="bg-primary">Detalhes</th>
              <td>
                <p>
                  Backend:&nbsp;
                  <a
                    href="https://railway.app/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span>Railway</span>
                  </a>
                  <br /> Banco de dados:&nbsp;
                  <a
                    href="https://www.postgresql.org/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span>Postgresql</span>
                  </a>
                  <br /> Front end web:&nbsp;
                  <a
                    href="https://app.netlify.com/signup"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span>Netlify</span>
                  </a>
                </p>
              </td>
            </tr>
          </table>
        </div>
        <ContributionDsVendas />
        <LicenseCardDsVendas />
      </div>
    </div>
  );
};

export default DsVendas;
