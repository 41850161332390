import { Projects } from 'types/project';
import './styles.css';
import ProjectCard from 'components/ProjectCard';
import { Outlet } from 'react-router-dom';
import { Link } from 'react-router-dom';

const Project = () => {
  const project: Projects = {
    id: 1,
    title: 'Catálogo de Calçados',
    subtitle: 'Calçados para todos os gostos.',
    description:
      'O sistema foi concebido e desenvolvido com o objetivo de atender às demandas do cliente, proporcionando uma exposição eficiente de seus produtos tanto para clientes quanto para colaboradores, de maneira intuitiva e ágil.O sistema deve gerenciar de forma eficiente o cadastro de usuários, produtos e suas respectivas categorias. Cada usuário deve ser registrado com informações detalhadas, incluindo nome, e-mail, telefone, data de nascimento e uma senha de acesso segura. No que diz respeito aos produtos, o sistema deve armazenar dados como nome, descrição, preço, imagem, SKU, marca, NCM (Nomenclatura Comum do Mercosul), código EAN (European Article Number), e data de fabricação. Para facilitar a experiência do usuário, o sistema deve oferecer um catálogo de produtos acessível, permitindo filtrar a busca pelo nome do produto. A partir desse catálogo, os usuários podem selecionar um produto específico para visualizar seus detalhes de maneira clara e intuitiva. Além disso, usuários com privilégios administrativos terão acesso a uma área exclusiva, a qual proporciona a administração dos cadastros de usuários, produtos e categorias de forma centralizada e eficaz. Isso possibilita uma gestão facilitada, contribuindo para a eficiência e organização do sistema como um todo.',
    imgUrl:
      'https://ik.imagekit.io/dpiu5qcwt/desktop/frontend-gtincatalog.png?updatedAt=1703201351028',
  };

  const dscatalog: Projects = {
    id: 2,
    title: 'Página de Administração',
    subtitle: 'Sistema de cadastros e manuntenção.',
    description: 'Sistema de cadastro de produtos, categorias e usuários.',
    imgUrl:
      'https://ik.imagekit.io/dpiu5qcwt/desktop/Captura_16-December-2023_22-09-00.png?updatedAt=1702777194435',
  };

  const dsvendas: Projects = {
    id: 3,
    title: 'Dashboard de Vendas',
    subtitle: 'Listagem de vendedores e sua taxa de sucesso.',
    description: 'Dashboard de Vendas.',
    imgUrl:
      'https://ik.imagekit.io/dpiu5qcwt/desktop/dsvends-dashboard.png?updatedAt=1702951674759',
  };

  const dsdeliver: Projects = {
    id: 4,
    title: 'Sabor Expresso',
    subtitle: 'Comida Rápida sem Sair de Casa.',
    description: 'Sabor Expresso.',
    imgUrl:
      'https://ik.imagekit.io/dpiu5qcwt/desktop/DsDeliver-desk-mobile.png?updatedAt=1703594023970',
  };
  return (
    <>
      <div className="container my-4 project-container">
        <div className="row project-title-container">
          <h1>Projetos</h1>
        </div>

        <div className="row project-card-container">
          <div className="col-sm-6 col-lg-4 col-xl-3">
            <Link to="/gtincatalog">
              <ProjectCard project={project} />
            </Link>
          </div>
          <div className="col-sm-6 col-lg-4 col-xl-3">
            <Link to="/dscatalog">
              <ProjectCard project={dscatalog} />
            </Link>
          </div>
          <div className="col-sm-6 col-lg-4 col-xl-3">
            <Link to="/dsvendas">
              <ProjectCard project={dsvendas} />
            </Link>
          </div>
          <div className="col-sm-6 col-lg-4 col-xl-3">
            <Link to="/dsdeliver">
              <ProjectCard project={dsdeliver} />
            </Link>
          </div>
          {/* <div className="col-sm-6 col-lg-4 col-xl-3">
            <Link to="5">
              <ProjectCard project={project} />
            </Link>
          </div> */}
        </div>
        {/* <div className="row">
          <Pagination />
        </div> */}
      </div>
      <Outlet />
    </>
  );
};

export default Project;
